import React, { useEffect } from "react";
import { Link } from "gatsby";
import Layout from "../layouts/ja";
import SEO from "../components/seo";
import $ from "jquery"

const FaqPage = ({ location }) => {

  useEffect(() => {
    expandData()
  }, []);

  const expandData = () => {
    $(".faq__title").on("click", function (e) {

      if (!$(this).hasClass("faq__title--active")) {
        var active = false;
      }
      $(".faq__title").removeClass("faq__title--active");
      if (active == false) {
        $(this).addClass("faq__title--active");
      }
    });
  }

  const specific_date = new Date('2022-01-09');
  const current_date = new Date();

  return (
    <Layout location={location}>
      <SEO title="よくあるご質問 " />

      <div id="contentWrap">
        <div id="index_content" className="admin-setting">
          <div className="faq">
            <h1 className="text--center">よくあるご質問</h1>
            <div className="container container--800">
              <div className="faq__row">
                <label className="faq__title" htmlFor="faq_1">
                  アヤナリワードとはなんですか？
                  </label>
                <div className="faq__content">
                  <div className="faq__content__inner">
                    <p className="p--sm">
                      アヤナリワードは、アヤナ ヴィラズ バリ、アヤナ セガラ バリ、アヤナ リゾート バリ、リンバ by アヤナ バリ、アヤナ コモド ワエチチュ ビーチ、アヤナ ラコディア、アヤナ ミッドプラザ ジャカルタ、デロニクス ホテル カラワンでの宿泊、飲食、遊びを楽しむロイヤルカスタマーのためのポイントプログラムです。会員限定の特典やリワードポイントは、対象ホテルでの無料宿泊やアップグレードなど、思い出に残る旅行体験と交換することができます。
                    </p>
                  </div>
                </div>
              </div>
              <div className="faq__row">
                <label className="faq__title" htmlFor="faq_2">
                  どのホテルが参加していますか？
                  </label>
                <div className="faq__content">
                  <div className="faq__content__inner">
                    <p className="p--sm">
                      A：参加ブランドは以下の通りです。アヤナヴィラズバリ、アヤナセガラバリ、アヤナリゾートバリ、リンバ by アヤナバリ、アヤナコモドワエチチュビーチ、アヤナラコディア、アヤナミッドプラザジャカルタ、デロニックスホテルカラワン
                        <br />
                        &nbsp;
                      </p>
                  </div>
                </div>
              </div>
              <div className="faq__row">
                <label className="faq__title" htmlFor="faq_3">
                  入会方法をおしえてください。
                  </label>
                <div className="faq__content">
                  <div className="faq__content__inner">
                    <p className="p--sm">
                      A:当ウェブサイトayanarewards.comよりご入会いただけます。
                        <br />
                        &nbsp;
                      </p>
                  </div>
                </div>
              </div>
              <div className="faq__row">
                <label className="faq__title" htmlFor="faq_4">
                  アヤナリワードに入会すると費用はかかりますか？
                  </label>
                <div className="faq__content">
                  <div className="faq__content__inner">
                    <p className="p--sm">
                      A:プログラムへは無料で入会できます。
                      </p>
                  </div>
                </div>
              </div>
              <div className="faq__row">
                <label className="faq__title" htmlFor="faq_5">
                  ポイントの獲得方法と、宿泊予約について何ポイントを獲得できるかおしえてください。
                  </label>
                <div className="faq__content">
                  <div className="faq__content__inner">
                    {current_date.getTime() > specific_date.getTime() ? (
                        <>
                        <p className="p--sm">
                            A：デロニクスホテルカラワンを除き、参加ホテルで10,000ルピアを支払う毎に1ポイントを獲得できます。ただし、デロニクスホテルカラワンでは、対象となる料金で15,000ルピアを支払う毎に1ポイントを獲得できます。ポイントは、税金とサービス料加算前の金額に対して適用されます。ポイントは、自動的にアヤナリワードのアカウントへと加算されます。 
                            アヤナリワードゴールド会員は、対象となる料金の支払いに対して追加の10％ボーナスポイントを獲得し、プラチナ会員は、追加の25％ボーナスポイントを獲得します。
                            <br />
                            参加ホテルの対象料金の詳細については、利用規約を参照してください：&nbsp;
                            <Link to="/ja/terms-and-conditions-8"
                            style={{
                              fontSize: "15px",
                              fontVariantLigatures: "common-ligatures",
                            }}>
                            <span style={{ color: "rgb(0, 0, 0)" }}>
                              {`${location.origin}/terms-and-conditions-8`}
                            </span>
                          </Link>
                            。
                        </p>
                        </>
                        ) : (
                        <>
                        <p className="p--sm">
                          A:参加ホテルでのご利用IDR10,000ルピア毎で1ポイントを獲得できます。獲得されたポイントは自動的にお客様のアカウントに加算されます。アヤナリワードのゴールドメンバーは10%のボーナスポイント、プラチナメンバーは15%のボーナスポイントが、ホテルでIDR10,000ルピアをご利用されるごとに加算されます。
                            <br />
                            ご利用されますホテルの対象料金の詳細につきましては、利用規約をご確認ください:&nbsp;
                            <Link to="/ja/terms-and-conditions-8"
                            style={{
                              fontSize: "15px",
                              fontVariantLigatures: "common-ligatures",
                            }}>
                            <span style={{ color: "rgb(0, 0, 0)" }}>
                              {`${location.origin}/terms-and-conditions-8`}
                            </span>
                          </Link>
                            。
                        </p>
                        </>
                    )}
                  </div>
                </div>
              </div>
              <div className="faq__row">
                <label className="faq__title" htmlFor="faq_6">
                  1回の滞在で何部屋までポイントを獲得することができますか？
                  </label>
                <div className="faq__content">
                  <div className="faq__content__inner">
                    <p className="p--sm">
                      {current_date.getTime() > specific_date.getTime() ? (
                          <>
                          A：複数の客室のうちの1室に会員本人が滞在し、各部屋の料金をチェックアウト時に会員自らが支払いを行う場合、同一期間に滞在する参加施設で最大5室分のポイントを獲得できます。
                          </>
                          ) : (
                          <>
                          A:
                          同じ施設で、同じ滞在日数の場合、最大5部屋までポイントを獲得できます。全てのお部屋のお支払いが会員様によって行われ、そのうちの1部屋に会員様ご自身がご滞在頂くことが条件となります。
                          </>
                      )}
                      </p>
                  </div>
                </div>
              </div>
              <div className="faq__row">
                <label className="faq__title" htmlFor="faq_7">
                  私がホテルに泊まっていなくても、私がお部屋の支払いをした場合、ポイントは取得できますか？
                  </label>
                <div className="faq__content">
                  <div className="faq__content__inner">
                    <p className="p--sm">
                      A:
                      いいえ。このプログラムでは、ポイント取得のために会員ご自身がホテルに滞在して頂く必要があります。
                      </p>
                  </div>
                </div>
              </div>
              <div className="faq__row">
                <label className="faq__title" htmlFor="faq_8">
                  ポイントの引き換えはどうすればできますか？
                  </label>
                <div className="faq__content">
                  <div className="faq__content__inner">
                    <p className="p--sm">
                      {current_date.getTime() > specific_date.getTime() ? (
                          <>
                          A：ayanarewards.comからアカウントにログインし、交換ページにアクセスして、特典カタログを表示し、ライフスタイルや旅行体験などお好きな特典と引き換えることができます。
                          </>
                          ) : (
                          <>
                          A:
                          ayanarewards.comよりアカウントにログインしていただければ、リワードカタログや引き換え内容をご覧いただけます。お好きなリワードと引き換えてより充実したご滞在をお楽しみいただけます。
                          </>
                      )}
                      </p>
                  </div>
                </div>
              </div>
              <div className="faq__row">
                <label className="faq__title" htmlFor="faq_9">
                  ポイントのリワード交換ができません。
                  </label>
                <div className="faq__content">
                  <div className="faq__content__inner">
                    <p className="p--sm">
                      A:交換が出来ないケースがいくつかあります：
                        <br />
                        a.
                        お選びいただいたリワードのポイントに不足している場合。ポイントに見合ったリワードと交換されるか、十分なポイントを貯めてから交換してください。
                        <br />
                        b. ポイントの有効期限が過ぎている場合。 c.
                        ご希望のリワードが在庫切れの状態である場合。この場合は通常、在庫が補充され次第、ウェブサイトにてお知らせしております。
                        <br />
                        上記以外でポイント交換についてご不明な点がございましたら、お気軽にayanarewards.comまでご連絡ください。
                      </p>
                  </div>
                </div>
              </div>
              <div className="faq__row">
                <label className="faq__title" htmlFor="faq_10">
                  リワードポイントを獲得してから自分のアカウントに入るまで、どのくらいの期間かかりますか？
                  </label>
                <div className="faq__content">
                  <div className="faq__content__inner">
                    <p className="p--sm">
                      A:　ポイントは、チェックアウト後
                      最大7営業日以内に会員様のアカウントに加算されます。
                      </p>
                  </div>
                </div>
              </div>
              <div className="faq__row">
                <label className="faq__title" htmlFor="faq_11">
                  アヤナリワードポイントの有効期限はどのくらいですか？
                  </label>
                <div className="faq__content">
                  <div className="faq__content__inner">
                    <p className="p--sm">
                      A: ポイントは発行日から10年間有効です。
                    </p>
                  </div>
                </div>
              </div>
              <div className="faq__row">
                <label className="faq__title" htmlFor="faq_12">
                  現在のポイント残高や明細、利用状況を知りたいです。
                  </label>
                <div className="faq__content">
                  <div className="faq__content__inner">
                    <p className="p--sm">
                      {current_date.getTime() > specific_date.getTime() ? (
                          <>
                          A：ayanarewards.comにログインすると、プロファイルメニューからすべてのポイントの詳細を確認できます。
                          </>
                          ) : (
                          <>
                          A:
                          ポイント明細はayanarewards.comよりログインしてご覧いただけます。
                          </>
                      )}
                      </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default FaqPage;
